import {EmbedConfig} from './embedConfig';
import VimeoEmbedConfig from './vimeoEmbedConfig';
import YoutubeEmbedConfig from './youtubeEmbedConfig';


export const createEmbedConfig = (url: string): EmbedConfig | null => {
    const embedConfig = [
        YoutubeEmbedConfig,
        VimeoEmbedConfig,
    ].find((entry) => entry.isMatch(url));
    return embedConfig ? new embedConfig(url) : null;
};
