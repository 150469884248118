







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SortIcon extends Vue {
  @Prop({default: 'none'}) public state!: 'none' | 'asc' | 'desc';

}
