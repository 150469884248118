











import PrimaryButton from '@/components/PrimaryButton.vue';
import {VNode} from 'vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {PrimaryButton},
})
export default class VForm extends Vue {
  @Prop({}) public action!: string;
  @Prop({}) public method!: string;
  @Prop({}) public messages!: { [key: string]: string };
  @Prop({}) public additionalValidation!: () => boolean;
  @Prop({default: 'Submit'}) public submitLabel!: string;
  @Prop({default: 'Sending'}) public submitSendingLabel!: string;

  public failedSubmissionOnce = false;
  public responseStatus = '';
  public responseMessage = '';
  private sending: boolean = false;

  public validate() {
    const nodes = this.getDefaultSlotComponents();
    nodes.forEach((vNode) => {
      if (vNode.componentInstance && typeof (vNode.componentInstance as any).validate === 'function') {
        (vNode.componentInstance as any).validate();
      }
    });

  }

  public async submit(event: Event) {
    this.validate();
    this.sending = true;
    const isValid = await this.isValid();
    if (isValid) {
      this.failedSubmissionOnce = true;
      (event.target as HTMLFormElement).submit();
    } else {
      this.failedSubmissionOnce = true;
    }
    this.sending = false;
  }

  protected onInput() {
    this.failedSubmissionOnce = false;
  }

  private isValidAdditional() {
    if (typeof this.additionalValidation === 'function') {
      return this.additionalValidation();
    }
    return true;
  }

  private getDefaultSlotComponents(): VNode[] {
    if (this.$slots.default) {
      const nodes = this.$slots.default;
      if (Array.isArray(nodes)) {
        return nodes;
      }
    }
    return [];
  }

  private checkAllBooleansMatch(booleans: boolean[], match: boolean) {
    // Check if NOT some of the `booleans` are NOT `match`
    // Using some because it exits early on a match instead of having to parse the entire array
    return !booleans.some((v) => v === !match);
  }


  private async isValid(): Promise<boolean> {
    const asyncBeforeSubmissions: boolean[] = [];
    // If the regular form is valid
    if (this.$el && (this.$el as HTMLFormElement).checkValidity()) {
      // Only then proceed to async checks
      const nodes = this.getDefaultSlotComponents();
      for (const node of nodes) {
        if (node.componentInstance && typeof (node.componentInstance as any).beforeSubmission === 'function') {
          const passed = await (node.componentInstance as any).beforeSubmission();
          asyncBeforeSubmissions.push(passed);
        }
      }
      return this.checkAllBooleansMatch(asyncBeforeSubmissions, true);
    } else {
      return false;
    }
  }

}
