


























import DocumentIcon from '@/assets/icons/icons-document.svg?inline';
import SortIcon from '@/components/SortIcon.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import natsort from 'natsort';

interface DataTableHeader {
  name: string;
  value: string;
  sortable?: boolean;
}

interface DataTableRow {
  name: string;
  entries: DataTableCell[];
}

interface DataTableCell {
  value: string;
  type: 'string' | 'number';
  sortValue: 'string';
  download?: boolean;
}

interface DataTableContent {
  headers: DataTableHeader[];
  rows: DataTableRow[];
}

@Component({
  components: {SortIcon, DocumentIcon},
})
export default class DataTable extends Vue {

  private currentSort: number = 0;
  private currentSortDir: 'asc' | 'desc' = 'asc';

  @Prop({default: {}}) private content!: DataTableContent;
  @Prop({default: ''}) private defaultSort!: string;

  get headers(): DataTableHeader[] {
    return this.content.headers;
  }

  get sorted(): DataTableRow[] {
    const sorter = natsort();
    return this.content.rows.sort((a, b) => {
      const modifier = (this.currentSortDir === 'desc') ? -1 : 1;
      const aEntries: DataTableCell[] = a.entries;
      const bEntries: DataTableCell[] = b.entries;
      const aCell: DataTableCell = aEntries[this.currentSort];
      const bCell: DataTableCell = bEntries[this.currentSort];

      return sorter(aCell.sortValue, bCell.sortValue) * modifier;

    });
  }

  private getSortState(index: number): 'none' | 'asc' | 'desc' {
    if (index !== this.currentSort) {
      return 'none';
    }
    return this.currentSortDir;
  }

  private created() {
    const startSort = this.content.headers.findIndex((value: DataTableHeader) => {
      return value.name === this.defaultSort;
    });
    this.currentSort = startSort || 0;
  }

  private sort(index: number): void {
    // if index == current sort, reverse
    if (index === this.currentSort) {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
    }
    this.currentSort = index;
  }
}

