import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {DirectiveOptions} from 'vue/types/options';

gsap.registerPlugin(ScrollTrigger);

const enterFade: DirectiveOptions = {
    inserted: (el, binding) => {
        const triggerSelector = el.dataset.triggerSelector || '.container';
        let trigger: HTMLElement;
        if (triggerSelector === 'self') {
            trigger = el;
        } else {
            trigger = el.closest(triggerSelector) || el;
        }

        gsap.from(
            el,
            {
                opacity: 0,
                scrollTrigger: {
                    trigger,
                    start: 'top 100%',
                    end: 'top 50%',
                    scrub: true,
                    markers: false,
                },
            },
        );
    },
};
export default enterFade;
