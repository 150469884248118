












import ArrowRightIcon from '@/assets/icons/icons-arrow-right.svg?inline';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
    ArrowRightIcon,
  },
})
export default class PrimaryButton extends Vue {
  @Prop({default: 'Submit'}) private label!: string;
  @Prop({default: 'submit'}) private type!: string;
  @Prop({default: false}) private inverted!: boolean;
  @Prop({default: false}) private sending!: boolean;
  @Prop({default: 'Sending'}) private sendLabel!: boolean;
}
