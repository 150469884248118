
























import {Component, Prop, Vue} from 'vue-property-decorator';
import IconsDocument from '@/assets/icons/icons-document.svg?inline';
import Graph from '@/components/Graph.vue';
import axios from 'axios';

interface GraphItemData {
  indicativeCost: string;
  volume: string;
}

interface GraphItem {
  solutionName: string;
  data: Array<GraphItemData>;
}

@Component({
  components: {
    IconsDocument,
    Graph,
  },
})
export default class GraphComponent extends Vue {
  @Prop() private readonly eyebrow!: string;
  @Prop() private readonly title!: string;
  @Prop() private readonly subTitle!: string;
  @Prop() private readonly body!: string;
  @Prop() private readonly buttonLabel!: string;
  @Prop() private readonly compositeKey!: string;
  @Prop() private readonly graphTitle?: string;
  @Prop() private readonly yAxisTitle?: string;
  @Prop() private readonly xAxisTitle?: string;

  private graphContent?: Array<GraphItem> = [];

  private created() {
    axios.get(`${_mgnl.contextPath}/.rest/chart/v1/manufacturing-costs/${this.compositeKey}`)
      .then((response) => {
        this.graphContent = response.data as Array<GraphItem>;
      });
  }

  private mapToGraphData(data: Array<GraphItem>) {
    return data.map((item) => {
      return {
        name: item.solutionName,
        data: item.data.map((dataItem) => {
          return {
            x: dataItem.volume,
            y: dataItem.indicativeCost,
          };
        }),
      };
    });
  }

  private download() {
    const graph = this.$refs.graph as Graph;
    if (graph) {
      graph.download();
    }
  }
}
