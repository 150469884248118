






import {Component, Prop, Vue} from 'vue-property-decorator';
import { jsPDF } from 'jspdf';
import {Chart, ChartConfiguration, ChartData, ChartDataset, registerables} from 'chart.js';

Chart.register(...registerables);

@Component({})
export default class Graph extends Vue {
  @Prop() private readonly graphContent!: object[];
  @Prop() private readonly graphTitle?: string;
  @Prop() private readonly yAxisTitle?: string;
  @Prop() private readonly xAxisTitle?: string;
  private readonly colors = ['#FF000F', '#00D9D9', '#2B72BA', '#808080',
    '#000000', '#91A6B8', '#D1D9E0', '#F7B500',
    '#6DD400', '#B620E0'];

  private chart?: Chart;

  public download() {
    if (this.chart) {
      const pdf = new jsPDF('landscape');
      const imgData = (this.$refs.chart as HTMLCanvasElement).toDataURL('image/png', 1.0);

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdfWidth,
          pdfHeight,
      );
      pdf.save(`${this.graphTitle || 'Chart'}.pdf`);
    }
  }

  private createChart(chartData: ChartData) {
    const canvas = this.$refs.chart as HTMLCanvasElement;

    const options: ChartConfiguration = {
      type: 'line',
      data: chartData,
      options: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                size: 16,
              },
              padding: 20,
            },
          },
          title: {
            display: true,
            text: this.graphTitle || 'Chart',
            font: {
              size: 18,
              weight: 'bold',
            },
            padding: 30,
            color: '#db000d',
          },
        },
        responsive: true,
        scales: {
          y: {
            display: true,
            title: {
              color: '#141414',
              display: true,
              text: this.yAxisTitle || 'Y axis',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            bounds: 'data',
            display: true,
            type: 'logarithmic',
            title: {
              color: '#141414',
              display: true,
              text: this.xAxisTitle || 'X axis',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            ticks: {
              color: '#141414',
              font: {
                weight: 'bold',
              },
            },
            grid: {
              display: false,
            }
          },
        },
        maintainAspectRatio: false,
      },
    };

    this.chart = new Chart(canvas, options);
  }

  private mounted() {
    let index = 0;
    const dataset: ChartDataset[] = [];
    this.graphContent.forEach((item: any) => {

      if (index >= this.colors.length) {
        index = 0;
      }

      const color = this.colors[index++];

      const mapped: ChartDataset = {
        label: item.name,
        fill: false,
        backgroundColor: color,
        borderColor: color,
        data: [],
        tension: 0.1,
        cubicInterpolationMode: 'monotone',
        radius: 0,
      };

      item.data.forEach((dataRow: {x: string, y: string}) => {
        mapped.data.push({
          x: parseFloat(dataRow.x),
          y: parseFloat(dataRow.y),
        });
      });

      dataset.push(mapped);
    });

    this.createChart({datasets: dataset});
  }
}
