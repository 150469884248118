













import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Component, Prop, Vue} from 'vue-property-decorator';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {},
})
export default class Feature extends Vue {
  private showText = false;
  private toggleElement: Element | null = null;
  @Prop({default: 'Open'}) private textOpen!: string;
  @Prop({default: 'Close'}) private textClose!: string;
  private currentText: string = '';

  private mounted() {
    this.toggleElement = this.$el.querySelector('.feature__toggle') as Element;
    this.toggleElement.addEventListener('click', this.toggleState);
    this.setText(this.textOpen);
  }

  private setText(text: string) {
    this.currentText = text;
  }
  private toggleState() {
    this.showText = !this.showText;
    this.setText(this.showText ? this.textClose : this.textOpen);
  }
  private textEnterAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }
  private textLeaveAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }
}
