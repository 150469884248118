const gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1440,
    hd: 1920,
} as const;

export type Breakpoint = keyof typeof gridBreakpoints;

export const mqMinWidth: (mq: Breakpoint) => MediaQueryList = (mq: Breakpoint) => window.matchMedia(`(min-width: ${gridBreakpoints[mq]}px)`);
export const mqMaxWidth: (mq: Breakpoint) => MediaQueryList = (mq: Breakpoint) => window.matchMedia(`(max-width: ${gridBreakpoints[mq] - 0.02}px)`);
