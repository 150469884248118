






import decimalPlaces from '@/utils/decimalPlaces';
import {gsap, Power0} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Component, Vue} from 'vue-property-decorator';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
  },
})
export default class Statistic extends Vue {
  private valueElement: Element | null = null;
  private textValue: string = '';
  private value: number = 0;
  private decimalPlaces: number = 0;
  private duration: number = 1;
  private current = {
    value: 0,
  };

  private mounted() {
    this.valueElement = this.$el.querySelector('.statistic__value__value');
    if (this.valueElement) {
      this.textValue = this.valueElement.textContent || '';
      this.value = parseFloat(this.textValue);
      this.decimalPlaces = decimalPlaces(this.value);

      if (isNaN(this.value)) {
        return;
      }

      (this.valueElement as Element).textContent = (0).toFixed(this.decimalPlaces);
      gsap.to(this.current, this.duration, {
        value: this.value,
        scrollTrigger: {
          trigger: this.$el, start: 'top 90%', toggleActions: 'play none none none',
        },
        onUpdate: () => {
          // Using textContent here because SVG does not support innerHTML and it's faster
          (this.valueElement as Element).textContent = this.current.value.toFixed(this.decimalPlaces);
        },
        onComplete: () => {
          (this.valueElement as Element).textContent = (this.valueElement as Element).textContent;
        }, ease: Power0.easeNone,
      });


    }
  }
}
