export const dataLayerPush = (event: string, label: string, value: string) => {
    // When a metric is selected
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
        event,
        [label]: value,
    });
};

export const dataLayerPushSelect = (label: string, value: string) => {
   dataLayerPush(`select_${label}`, label, value);
};
