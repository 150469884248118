import Vue from 'vue';
import Vuex from 'vuex';
import modal, {Modal} from './modules/modal';

Vue.use(Vuex);
Vue.config.devtools = true;

const debug = process.env.NODE_ENV !== 'production';

export declare interface StoreModules {
    modal: Modal;
}

export default new Vuex.Store<StoreModules>({
    modules: {
        modal,
    }, strict: debug,
});

