







import {Component, Prop, Vue} from 'vue-property-decorator';
import {gsap, TweenLite, Power2} from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

@Component({
})
export default class BackToTop extends Vue {
  @Prop({}) private message?: string;

  private scrollUp() {
    TweenLite.to(window, 1, {scrollTo: { y: 0, autoKill: true }, ease: Power2.easeOut });
  }
}
