import {DirectiveOptions} from 'vue/types/options';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const imageLoaded: DirectiveOptions = {
    bind: (el, binding) => {
        el.addEventListener('load', () => {
            ScrollTrigger.refresh(false);
        });
    },
};
export default imageLoaded;
