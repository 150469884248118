






























































































import {gsap} from 'gsap';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
  inheritAttrs: false,
})
export default class FormInputField extends Vue {

  /**
   * Things like inputmode, pattern, placeholder, minlength, maxlength, required, etc.
   * Are bound 1-on-1 through v-bind="$props"
   */

  @Prop({default: ''}) public validationName!: string;
  @Prop({default: ''}) public description!: string;
  @Prop({default: ''}) public validationMessage!: string;
  @Prop({default: ''}) public pattern!: string;

  public isValidatedOnce = false;
  protected focused: boolean = false;
  private touched: boolean = false;
  private filled: boolean = false;

  get type() {
    return this.$attrs.type || 'text';
  }

  get smartPattern() {
    if (this.pattern) {
      return this.pattern;
    } else if (this.type === 'email') {
      return `[^@\\s]+@[^@\\s]+\\.[^@\\s]+`;
    }
  }


  public validate() {
    this.isValidatedOnce = true;
    this.touched = true;
    return this.isValid();
  }

  protected onKey() {
    this.touched = true;
  }

  protected onInput() {
    this.isValidatedOnce = false;
  }

  protected isValid() {
    return this.touched ? this.checkValidity() : true;
  }

  protected getValidity() {
    const input = this.getInputField();
    return input ? input.validity : {};
  }

  protected updateValue(value: any) {
    this.filled = value.length > 0;
    this.$emit('input', value);
  }

  private getInputField(): HTMLInputElement | null {
    return this.$refs.input ? (this.$refs.input as HTMLInputElement) : null;
  }

  private checkValidity() {
    const input = this.getInputField();
    return input ? input.checkValidity() : false;
  }

  private enterAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

  private leaveAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

}
