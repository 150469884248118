




























import ArrowLeftIcon from '@/assets/icons/icons-arrow-left-l.svg?inline';

import ArrowRightIcon from '@/assets/icons/icons-arrow-right-l.svg?inline';
import enterUpFade from '@/directives/enterUpFade';
import {mqMinWidth} from '@/utils/mq';
import {gsap, Power1, Power2} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Component, Prop, Vue} from 'vue-property-decorator';
import smoothReflow from 'vue-smooth-reflow';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
  },
  directives: {
    enterUpFade,
  },
  mixins: [smoothReflow],
})
export default class CaseStudies extends Vue {
  private activeIndex: number = 0;
  private lastAction: 'prev' | 'next' = 'next';
  @Prop({default: 0}) private nums?: number;


  private mounted() {
    if (this.$smoothReflow) {
      this.$smoothReflow({
        el: (this.$refs.inset as HTMLElement),
        transition: 'height .5s .5s',
        hideOverflow: false,
      });
    }
  }

  private setActive(index: number) {
    this.activeIndex = index;
  }

  private prev() {
    if (!this.nums) {
      return;
    }
    this.activeIndex--;
    if (this.activeIndex < 0) {
      this.activeIndex = this.nums - 1;
    }
    this.lastAction = 'prev';
    this.scrollInview();
  }

  private next() {
    if (!this.nums) {
      return;
    }
    this.activeIndex++;
    if (this.activeIndex > this.nums - 1) {
      this.activeIndex = 0;
    }
    this.lastAction = 'next';
    this.scrollInview();
  }

  private scrollInview() {
    const nav = document.querySelector('.navigation') as HTMLElement;
    if (mqMinWidth('lg').matches) {
      gsap.to(window, {
        duration: .5, delay: .5, scrollTo: {y: this.$el, autoKill: true, offsetY: nav.clientHeight},
        ease: Power2.easeOut,
      });
    }
  }

  private enterAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 0.5,
      opacity: 0,
      delay: .5,
      x: (this.lastAction === 'next') ? '-10%' : '10%',
      ease: Power1.easeOut,
      onComplete: () => {
        done();
      },
    });
  }

  private leaveAnimation(el: HTMLElement, done: () => void) {
    gsap.set(el, {
      position: 'absolute',
      top: 0,
      width: el.clientWidth,
    });
    gsap.to(el, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeOut,
      x: (this.lastAction === 'prev') ? '-10%' : '10%',
      onComplete: () => {
        done();
      },
    });
  }

  private enterImageAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 1,
      opacity: 0,
      delay: 1,
      ease: Power1.easeInOut,
      onComplete: () => {
        ScrollTrigger.refresh(false);
        done();
      },
    });
  }

  private leaveImageAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeOut,
      onComplete: () => {
        ScrollTrigger.refresh(false);
        done();
      },
    });
  }


}
