/* tslint:disable */
require('./scss/main.scss');
document.addEventListener('touchstart', function () {
    //This is intentionally empty
}, false);
/* tslint:enable */

declare var __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + '/' + __webpack_public_path__;

import BackToTop from '@/components/BackToTop.vue';
import CaseStudies from '@/components/CaseStudies.vue';
import DataTable from '@/components/DataTable.vue';
import Feature from '@/components/Feature.vue';
import FormFileUpload from '@/components/FormFileUpload.vue';
import FormInputField from '@/components/FormInputField.vue';
import MaterialSelector from '@/components/MaterialSelector.vue';
import NavigationComponent from '@/components/NavigationComponent.vue';
import NavigationToggle from '@/components/NavigationToggle.vue';
import ScrollIndicator from '@/components/ScrollIndicator.vue';
import Statistic from '@/components/Statistic.vue';
import TheModal from '@/components/TheModal.vue';
import VForm from '@/components/VForm.vue';
import VideoComponent from '@/components/VideoComponent.vue';
import Graph from '@/components/Graph.vue';
import GraphComponent from '@/components/GraphComponent.vue';
import enterFade from '@/directives/enterFade';
import enterUpFade from '@/directives/enterUpFade';
import imageLoaded from '@/directives/imageLoaded';
import Vue from 'vue';
import store from '@/store';

Vue.config.productionTip = false;


const app = new Vue({
    store,
    comments: true,
    el: '#app',
    components: {
        navigation: NavigationComponent,
        NavigationToggle,
        ScrollIndicator,
        BackToTop,
        VideoComponent,
        VForm,
        FormInputField,
        FormFileUpload,
        Statistic,
        Feature,
        MaterialSelector,
        CaseStudies,
        DataTable,
        TheModal,
        Graph,
        GraphComponent,
    },
    directives: {
        imageLoaded,
        enterFade,
        enterUpFade,
    },
});


