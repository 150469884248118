













































































































































import DownloadButton from '@/components/DownloadButton.vue';
import {
  DataSheet,
  FlammabilityMaterial,
  MaterialSelectorObject,
  Metric,
  RangeMaterial,
  RangeMetric,
} from '@/interfaces/MaterialSelectorInterfaces';
import clamp from '@/utils/clamp';
import {dataLayerPushSelect} from '@/utils/dataLayerPush';
import {gsap, Power1} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import smoothReflow from 'vue-smooth-reflow';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
    DownloadButton,
  },
  mixins: [smoothReflow],
})
export default class MaterialSelector extends Vue {

  @Prop({default() {return {}; }}) private materialSelectorObject?: MaterialSelectorObject;

  private activeIndex = 0;
  private selectedDataSheet?: DataSheet | {} = {};

  get activeMetric(): Metric | undefined {
    if (this.metrics) {
      return this.metrics[this.activeIndex];
    }
  }

  get metrics(): Metric[] {
    if (this.materialSelectorObject && this.materialSelectorObject.metrics) {
      return this.materialSelectorObject.metrics;
    }
    return [];
  }

  get datasheets(): DataSheet[] {
    if (this.materialSelectorObject && this.materialSelectorObject.datasheets) {
      return this.materialSelectorObject.datasheets;
    }
    return [];
  }

  @Watch('activeMetric')
  private onActiveMetricChange(val: Metric) {
    dataLayerPushSelect('metric', val.name);
  }

  private detectManualDatasheetSelection() {
    dataLayerPushSelect('datasheet', (this.selectedDataSheet as DataSheet).name);
  }

  private setActiveMetric(index: number) {
    this.activeIndex = index;
  }

  private isActive(index: number) {
    return this.activeIndex === index;
  }

  private mounted() {
    if (this.$smoothReflow) {
      this.$smoothReflow({
        el: (this.$refs.parentElement as HTMLElement),
        hideOverflow: false,
        transitionEvent: {
          propertyName: 'opacity',
        },
      });
    }

    (this.$refs.metricListItems as HTMLElement[]).forEach((value, index) => {
      gsap.from(value, {
        duration: 0.3,
        delay: index * 0.1,
        scrollTrigger: {
          trigger: value, start: 'top 100%', toggleActions: 'play none none none',
        },
        opacity: 0, y: '-50%',
        ease: Power1.easeOut,
      });
    });

    if (this.datasheets) {
      this.selectedDataSheet = this.datasheets[0];
    }

  }

  private textEnterAnimation(el: HTMLElement, done: () => void) {
    const delay = el.dataset.delay || 0;
    const duration = el.dataset.duration || 0.3;
    gsap.from(el, {
      duration,
      delay,
      opacity: 0,
      ease: Power1.easeOut,
      onComplete: () => {
        done();
      },
    });
  }

  private barAnimation(el: HTMLElement, done: () => void) {
    const index = parseInt(el.dataset.index || '0', 10);
    const fill: HTMLElement = el.querySelector('.metric-display__item__bar__fill') as HTMLElement;
    const fillNumber: number = parseInt(fill.dataset.fill || '0', 10);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: el, start: 'top 100%', toggleActions: 'play none none none',
      },
      onComplete: () => {
        done();
      },
    });

    tl.from(el, {
      duration: 0.3,
      delay: 0.1 * index,
      opacity: 0,
      ease: Power1.easeOut,
    });

    tl.from(fill, {
      duration: 0.2 + fillNumber / 100,
      width: '0',
      ease: Power1.easeOut,
    });
  }

  private flammabilityAnimation(el: HTMLElement, done: () => void) {
    const index = parseInt(el.dataset.index || '0', 10);
    const fill = el.querySelector('.metric-display__item__bar__fill');

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: el, start: 'top 100%', toggleActions: 'play none none none',
      },
      onComplete: () => {
        done();
      },
    });

    tl.from(el, {
      duration: 0.3,
      delay: 0.1 * index,
      opacity: 0,
      ease: Power1.easeOut,
    });
    tl.from(fill, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeOut,
    });
  }


  private textLeaveAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

  private materialPercentageForMetric(metric: RangeMetric, material: RangeMaterial) {
    if (typeof (metric as Metric).scale === 'string') {
      console.warn('Attempting to use material percentage for flammability.');
      return 0;
    }
    const {scale} = metric;
    const scaleStart = scale[0];
    const scaleEnd = scale[1];
    const scaleRange = scaleEnd - scaleStart;
    const value = material.value;

    const percentage = ((value - scaleStart) / scaleRange) * 100;
    return clamp(percentage, 0, 100);
  }

  private getFlammabilityStyles(material: FlammabilityMaterial) {
    switch (material.value) {
      case '5VA/B':
        return 'width: 20%; transform: translateX(400%)';
      case 'V0':
        return 'width: 20%; transform: translateX(300%)';
      case 'V1':
        return 'width: 20%; transform: translateX(200%)';
      case 'V2':
        return 'width: 20%; transform: translateX(100%)';
      case 'HB':
        return 'width: 20%; transform: translateX(0%)';
      default:
        return '';
    }
  }

}
