







import {Component, Prop, Vue} from 'vue-property-decorator';
import {gsap, TweenLite, Power2} from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

@Component({
})
export default class ScrollIndicator extends Vue {
  @Prop({}) private message?: string;

  private scrollDown() {
    const parent = this.$el.parentElement;
    if (!parent) {
      return;
    }
    const {height} = parent.getBoundingClientRect();
    TweenLite.to(window, 1, {scrollTo: { y: height, autoKill: true }, ease: Power2.easeOut });
  }
}
