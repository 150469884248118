



















































import UploadIcon from '@/assets/icons/icons-upload.svg?inline';
import {gsap} from 'gsap';
import {Component, Prop, Vue} from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';

@Component({
  components: {
    UploadIcon,
    vueDropzone: vue2Dropzone,
  },
  inheritAttrs: false,
})
export default class FormFileUpload extends Vue {


  @Prop({default: ''}) public label!: string;
  @Prop({default: ''}) public validationName!: string;
  @Prop({default: ''}) public description!: string;
  @Prop({default: ''}) public name!: string;
  @Prop({default: false}) public error!: boolean;
  @Prop({default: false}) public required!: boolean;

  private hasFile = false;

  private preferredFileTypes = [
    '.stp',
    '.step',
    '.sldpart',
  ];

  private acceptedFiles = [
    '.stl',
    '.3mf',
    '.iges',
    '.igs',
    '.pdf',
    '.x_t',
    '.x_b',
    '.sat',
    '.catpart',
    '.3dxml',
    '.part',
    '.3dm',
    '.dxf',
    '.dwg',
    '.zip',
    '.doc',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
  ];

  private dropzoneOptions = {
    url: _mgnl.contextPath + '/.rest/azureBlobStorage/v1/prototypeFileUpload',
    thumbnailWidth: 150,
    maxFilesize: 10,
    maxFiles: 1,
    maxfilesexceeded(file: any) {
      (this as any).removeAllFiles();
      (this as any).addFile(file);
    },
    createImageThumbnails: false,
    autoProcessQueue: false,
    acceptedFiles: [...this.preferredFileTypes, ...this.acceptedFiles].join(','),
    previewTemplate: this.previewTemplate(),
  };
  private isValidatedOnce: boolean = false;

  public validate() {
    this.isValidatedOnce = true;
    return this.isValid();
  }

  protected isValid() {
    if (this.required === false) {
      return true;
    }
    return this.isValidatedOnce ? this.hasFile : true;
  }

  private previewTemplate() {
    return `<div class="dz-preview dz-file-preview">
      <div class="dz-details">
        <div class="dz-filename"><span data-dz-name></span></div>
        <div class="dz-size" data-dz-size></div>
        <img data-dz-thumbnail />
        <button data-dz-remove>Remove</button>
      </div>
      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      <div class="dz-success-mark"><span>✔</span></div>
      <div class="dz-error-mark"><span>✘</span></div>
      <div class="dz-error-message"><span data-dz-errormessage></span></div>
    </div>`;
  }

  private fileAdded(file: any) {
    this.hasFile = true;
  }

  private fileRemoved(file: any, error: any, xhr: any) {
    this.hasFile = false;
  }

  private checkValidity() {
    return this.hasFile;
  }

  private async beforeSubmission(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.hasFile) {
        if (this.required) {
          return resolve(false);
        }
        return resolve(true);
      }
      const dropzoneRef = this.$refs.dropzone;
      const dropzoneInstance = (dropzoneRef as any).dropzone;
      dropzoneInstance.processQueue();
      dropzoneInstance.on('error', (e: any, response: { error: { response: any; }; }, xhr: any) => {
        if (response.error && response.error.response) {
          alert('Error uploading file: \n' + response.error.response);
          reject(response.error.response);
        } else {
          alert('Error uploading file');
          reject(e);
        }
      });
      dropzoneInstance.on('success', (e: any, response: { fileUrl: string; }, xhr: any) => {
        (this.$refs.fileLink as HTMLInputElement).value = response.fileUrl;
        resolve(true);
      });
    });
  }


  private enterAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

  private leaveAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

}
