import {StoreModules} from '@/store';
import {Module} from 'vuex';

export type ModalContents = (() => void) | string;

export interface Modal {
    open: boolean;
    content?: ModalContents;
}

const module: Module<Modal, StoreModules> = {
    namespaced: true,
};

module.state = {
    open: false,
    content: undefined,
};

module.getters = {
    getContent(state) {
        return state.content;
    },
};

module.actions = {
    open({state, commit}, content) {
        commit('setContent', content);
        commit('setOpen', true);
    },
    close({state, commit}) {
        commit('setOpen', false);
    },
};

module.mutations = {
    setOpen(state, open: boolean) {
        state.open = open;
    },
    setContent(state, content: ModalContents) {
        state.content = content;
    },
};

export default module;

