

















import DocumentIcon from '@/assets/icons/icons-document.svg?inline';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
    DocumentIcon,
  },
})
export default class DownloadButton extends Vue {
  @Prop() private label!: string;
  @Prop() private url!: string;
  @Prop() private fileType!: string;
  @Prop() private fileSize!: string;
}
