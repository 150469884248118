







import NavigationToggle from '@/components/NavigationToggle.vue';
import {mqMaxWidth} from '@/utils/mq';
import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
  components: {NavigationToggle},
})
export default class NavigationComponent extends Vue {
  private sticky: boolean = false;
  private stickyClassName: string = 'navigation--sticky';
  private navigation?: HTMLDivElement;
  private expandedClassName: string = 'navigation--open';
  private expanded: boolean = false;
  private ariaExpanded?: string | null = null;
  private cssHeight: number = 96;
  private mq = mqMaxWidth('hd');
  @Prop({default: false}) private hideNavigation!: boolean;

  public handleExpansion() {
    this.expanded = !this.expanded;
    this.mqAriaHandler(this.mq);
  }

  private mounted() {
    this.navigation = this.$el as HTMLDivElement;

    this.scrollHandler();
    window.addEventListener('scroll', this.scrollHandler);

    this.mq.addEventListener('change', ((ev) => {
      this.mqAriaHandler(this.mq);
    }));
    this.mqAriaHandler(this.mq);
  }

  private mqAriaHandler(mq: MediaQueryList) {
    if (mq.matches) {
      this.ariaExpanded = this.expanded ? 'true' : 'false';
    } else {
      this.ariaExpanded = null;
      this.expanded = false;
    }
  }

  private scrollHandler(): void {
    if (!(this.navigation)) {
      return;
    }
    this.sticky = window.pageYOffset >= this.cssHeight;
  }


}
