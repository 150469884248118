





















import CloseIcon from '@/assets/icons/icons-burger-open.svg?inline';
import {StoreModules} from '@/store';
import {EmbedConfig} from '@/utils/embed-configs/embedConfig';
import {createEmbedConfig} from '@/utils/embed-configs/embedConfigFactory';
import {Component, Vue} from 'vue-property-decorator';
import {Store} from 'vuex';

@Component({
  components: {CloseIcon},
})
export default class TheModal extends Vue {


  private modalContents!: (() => void) | string | EmbedConfig;
  private modalType: 'component' | 'video' | 'image' | '' = ''; // Image is not yet implemented


  get component() {
    return (this.modalContents as () => {})();
  }

  get embedConfig(): EmbedConfig {
    return this.modalContents as EmbedConfig;
  }

  get isShowing() {
    return this.$store.state.modal.open;
  }

  public close() {
    if (this.modalType === 'video') {
      (this.modalContents as EmbedConfig).destroy();
    }
    this.$store.dispatch('modal/close');
  }

  private mounted() {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') { // escape key maps to keycode `27`
        this.close();
      }
    });
    (this.$store as Store<StoreModules>).watch((state) => {
      return state.modal.content && state.modal.open;
    }, () => {

      const content = this.$store.state.modal.content;

      if (typeof content === 'function') {
        this.modalContents = () => content;
        this.modalType = 'component';
        return;
      }

      if (typeof content === 'string') {
        const embedConfig = createEmbedConfig(content);
        if (!embedConfig) {
          return;
        }
        this.modalType = 'video';
        this.modalContents = embedConfig;
        const embed = (this.modalContents as EmbedConfig);
        this.$nextTick(() => {
          const embedTarget = (this.$refs.videoEmbed as HTMLElement)
              .querySelector<HTMLElement>('.embed-responsive-item');
          embed.setAutoplay(true);
          embed.initPlayer(embedTarget as HTMLElement);
        });
      }

    });
  }

  private afterModalClose() {
    this.modalType = '';
  }
}
