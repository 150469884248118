












import NavigationComponent from '@/components/NavigationComponent.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class NavigationToggle extends Vue {
  @Prop({default: null}) private  ariaExpanded?: string | null;

  public toggle() {
    (this.$parent as NavigationComponent).handleExpansion();
  }
}
