import { EmbedConfig } from './embedConfig';

declare global {
    interface Window {
        Vimeo: any;
    }
}

export default class VimeoEmbedConfig extends EmbedConfig {

    protected static matcher = /vimeo\.com\/(.*\/)?([\d]+)(.*)?/i;
    protected idPosition = 2;
    protected api = 'https://player.vimeo.com/api/player.js';

    constructor(protected url: string) {
        super(url);
        this.extractId(VimeoEmbedConfig.matcher);
    }

    public getThumb(): Promise<string> {
        return Promise.resolve('');
    }

    public initPlayer(target: HTMLElement): Promise<{ play: () => void; pause: () => void; player: any }> {
        return new Promise((resolve) => {
            this.loadApi().then(() => {
                const player = new window.Vimeo.Player(target, {
                    id: this.id,
                });
                player.on('play', () => this.handlers.play());
                player.on('pause', () => this.handlers.pause());
                player.on('ended', () => this.handlers.pause());
                this.actions.play = () => player.play();
                this.actions.pause = () => player.pause();
                this.actions.destroy = () => player.destroy();
                resolve(player);
            });
        });
    }

    protected loadApi(): Promise<any> {
        return this.addScript(() => {
            return !!window.Vimeo;
        });
    }
}
